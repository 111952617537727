<template>
  <transition-group name="fade">
    <div class="here bg-fff py-5" v-if="show" key="usuarios">
      <ModalNovoEditarUsuario
        :objPerfil="{ typePerfil: 5 }"
        :acao="acao"
        :dadosToEdit="editUser"
        @atualizaDados="atualizaDados"
      />
      <ModalConfirmacao
        :vontade="`que ${nameToDelete} seja`"
        :elementoVontade="'deletado'"
        @deletarUsuario="deletarAluno"
      />
      <modalVisualizaDados :dadosVisualizarUsuario="dadosVisualizarUsuario" />
      <!--
        "vontade",
        "elementoVontade", 
        "funcaoVontade",
        "condicional",
        "dispararEvento",
        "valorParaDispatch",
      -->
      <div class="container">
        <div class="row">
          <div class="lupa col-md-12 my-4 pl-0">
            <div class="position-relative">
              <img
                class="position-absolute"
                src="@/assets/icones/lupa.png"
                alt="lupa.png"
              />
              <input
                v-model="inputPesquisar"
                type="text"
                placeholder="Digite o nome ou o código para pesquisar "
              />
            </div>
            <!-- <button
              class="text-uppercase btn-style-default-less bg-222541 ml-3 mr-3 color-fff shadow-sm"
            >
              aplicar
            </button> -->
          </div>
          <div class="col-md-12 text-left pl-0 mb-3">
            <h2 class="title-dados pb-2">Alunos Cadastrados</h2>
          </div>
        </div>
      </div>
      <div class="container bg-fff in-here shadow-sm">
        <div class="row pb-4">
          <div
            class="
              col-md-11
              mx-auto
              h2
              row
              text-center
              border-bottom
              mt-5
              pb-2
              px-0
            "
          >
            <div class="col-md-2 pl-0">
              <h2>Código</h2>
            </div>
            <div class="col-md-2">
              <h2>Alunos</h2>
            </div>
            <div class="col-md-2">
              <h2>CPF</h2>
            </div>
            <div class="col-md-2">
              <h2>Cadastrado</h2>
            </div>
          </div>
          <div
            class="col-md-11 mx-auto h2 row text-left border-bottom py-3 px-0"
            v-for="(item, index) in alunos"
            :key="index"
          >
            <div class="col-md-2 d-flex j-c-center pl-0">
              <h3>{{ item.cod }}</h3>
            </div>
            <div class="col-md-2 d-flex j-c-center">
              <h3>{{ item.name }}</h3>
            </div>
            <div class="col-md-2 d-flex j-c-center">
              <h3>{{ itemCPF(item.cpf, trataCPF) }}</h3>
            </div>
            <div class="col-md-2 d-flex j-c-center">
              <h3>{{ item.created_at | trataDataHora }}</h3>
            </div>
            <div class="col-md-4 d-flex justify-content-end pr-0">
              <button
                class="
                  btn-style-default-less-2
                  color-fff
                  bg-42cc7e
                  mr-3
                  p-3
                  tamanho
                "
                @click="visualizarPerfil(item)"
              >
                <i class="fas fa-address-card"></i>
              </button>
              <!-- <button
                class="btn-style-default-less-2 color-fff bg-fedd0a mr-3 p-3 tamanho"
                @click="editarUsuario(item)"
              >
                <i class="fa fa-edit cor"></i>
              </button> -->
              <Loader v-if="loader" />
              <button
                v-if="item.id != $store.getters.getUserDatas.id"
                @click="VerificarDeletarAluno(item)"
                class="
                  btn-style-default-less-2
                  color-fff
                  bg-d42338
                  mr-3
                  p-3
                  tamanho
                "
              >
                <i class="fa fa-trash-o" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <!-- <div class="col-md-11 mx-auto d-flex justify-content-start mt-5 px-0">
            <button
              class="btn-style-default-less-2 color-fff bg-0e5daf"
              @click="adicionarUsuario()"
            >
              novo aluno
              <i class="icon-plus ml-1"
                ><img src="@/assets/icones/iconPlus.png" alt="iconPlus.png"
              /></i>
            </button>
          </div> -->
        </div>
      </div>
    </div>
  </transition-group>
</template>

<script>
import ModalNovoEditarUsuario from "@/components/modais/ModalNovoEditarUsuario";
import ModalConfirmacao from "@/components/modais/ModalConfirmacao";
import functionsParaCrudUsuarios from "@/mixins/functionsParaCrudUsuarios";
import modalVisualizaDados from "@/components/modais/ModalVisualizaDados";
import { mapGetters, mapActions } from "vuex";
import Loader from "@/components/Loader";

export default {
  name: "theAdministradorUsuario",
  data() {
    return {
      alunos: [],
      dadosVisualizarUsuario: {},
      loader: false,
    };
  },
  mixins: [functionsParaCrudUsuarios],
  computed: {
    ...mapGetters(["getAlunos"]),
  },
  components: {
    ModalNovoEditarUsuario,
    ModalConfirmacao,
    modalVisualizaDados,
    Loader,
  },
  watch: {
    inputPesquisar: function () {
      this.alunos = this.filtrosDePesquisaDados(this.inputPesquisar, "aluno");
    },
  },
  mounted() {
    this.alunos = this.filtrosDePesquisaDados("", "aluno");
    this.show = true;
    this.$store.dispatch(
      "getDadosDeTodosUsuarios",
      this.$store.getters.getPerfilToken
    );
  },
  methods: {
    itemCPF(cpf, trataCPF) {
      if (cpf == "" || cpf == null) {
        return "Não cadastrado";
      } else {
        cpf.replace(/[^\d]/g, "");
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      }
    },
    atualizaDados() {
      this.getDadosDeTodosUsuarios = this.$store.getters.getPerfilToken;
      this.$store.dispatch(
        "getDadosDeTodosUsuarios",
        this.$store.getters.getPerfilToken
      );
      setTimeout(() => {
        this.alunos = this.filtrosDePesquisaDados("", "aluno");
      }, 1020);
    },
    visualizarPerfil(val) {
      this.showModal("modalNameVisualizaDados");
      this.dadosVisualizarUsuario = val;
    },
    VerificarDeletarAluno(item) {
      this.showModal("modalNameConfirmacao");
      this.DadosToDelete = item;
    },
    deletarAluno() {
      this.loader = true;
      let objToDelete = {
        typeAjax: "DELETE",
        cod: this.DadosToDelete.id,
      };
      this.$store.dispatch("delUser", objToDelete).then((resolve) => {
        if (resolve.valid) {
          this.loader = false;
          this.toastGlobal(
            "success",
            `O aluno ${this.DadosToDelete.name} foi deletado com sucesso.`
          );

          this.hideModal("modalNameConfirmacao");
          this.atualizaDados();
        } else {
          this.loader = false;
          this.toastGlobal("error", resolve.msg.reject.msg);
        }
      });
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: 14px;
}

h3,
button {
  font-size: 12px;
  font-weight: 400;
}
.tamanho {
  height: 50px;
}
.cor {
  color: #000;
}

input {
  height: 40px;
  border: 1px solid #e4e5e8;
}

.border-bottom {
  border-bottom: 1px solid #e4e5e8;
}
</style>
